<template>
    <div class="card mb-3">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col">
                    <h5 class="fs-0 mb-0">Printers</h5>
                </div>
                <div class="col-auto">
                    <router-link :to="{ name: 'create-printer' }"
                                 class="btn btn-falcon-default btn-sm"
                                 v-if="$auth.hasPermissions('create:printers')">
                        <span class="fas fa-plus mr-1" data-fa-transform="shrink-3" />Add Printer
                    </router-link>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <LoadingSpinner v-if="loading" class="my-5" />
            <LoadingError v-else-if="error" class="my-6" />
            <VTable v-else-if="printers.length"
                    :data="printers"
                    :page-size="15">
                <template #header="{ sort, sortBy }">
                    <tr>
                        <th />
                        <VTableHeader field="name" v-bind="{ sort, sortBy }">
                            Printer Name
                        </VTableHeader>
                        <VTableHeader field="paper_size" v-bind="{ sort, sortBy }">
                            Paper Type
                        </VTableHeader>
                        <th />
                    </tr>
                </template>
                <template #row="{ item: printer }">
                    <tr :id="printer.id" class="btn-reveal-trigger">
                        <td class="py-2 align-middle text-center white-space-nowrap" style="min-width: 40px;">
                            <div v-if="printer === defaultPrinters[printer.paper_size]"
                                 v-tooltip="{ placement: 'right' }"
                                 :title="`This is your default printer for ${humanizePaperSize(printer.paper_size)}-sized documents`">
                                <span class="fa fa-bookmark text-500" />
                            </div>
                        </td>
                        <td class="py-2 align-middle">{{ printer.name }}</td>
                        <td class="py-2 align-middle">
                            <span v-if="printer.paper_size === 'LETTER'">Letter</span>
                            <span v-else-if="printer.paper_size === 'LABEL'">Label</span>
                            <span v-else class="text-500">Unknown</span>
                        </td>
                        <td class="py-2 align-middle white-space-nowrap">
                            <div class="dropdown text-sans-serif" v-if="$auth.hasSomePermission('delete:printers')">
                                <button class="btn btn-link btn-sm text-600 dropdown-toggle btn-reveal mr-3"
                                        type="button"
                                        :disabled="editLock"
                                        data-toggle="dropdown">
                                    <span class="fas fa-ellipsis-h fs--1" />
                                </button>
                                <div class="dropdown-menu dropdown-menu-right border py-0">
                                    <div class="py-2">
                                        <button v-if="printer != defaultPrinters[printer.paper_size]"
                                                class="dropdown-item"
                                                @click="setPrinterAsDefault(printer)">
                                            Set as my default {{ humanizePaperSize(printer.paper_size) }} printer
                                        </button>
                                        <button v-else
                                                class="dropdown-item"
                                                @click="unsetPrinterAsDefault(printer)">
                                            Clear as default {{ humanizePaperSize(printer.paper_size) }} printer
                                        </button>
                                        <button class="dropdown-item text-danger"
                                                @click="deletePrinter(printer)"
                                                v-if="$auth.hasPermissions('delete:printers')">
                                            Remove printer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </VTable>
            <div v-else class="d-flex align-items-center justify-content-center flex-column p-3" style="height: 300px;">
                <i class="fas fa-tint-slash" style="font-size: 2rem;" />
                <span class="mt-3">No printers configured yet.</span>
                <small class="mt-2 w-50 text-center">
                    Once you add your first printer, it will appear in this section
                </small>
            </div>
        </div>
    </div>
</template>

<script>
    import { deletePrinter, getDefaultPrinters, getPrinterList, setDefaultPrinter, unsetDefaultPrinter }
        from '../services/PrinterService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';
    import VTable from '../components/VTable.vue';
    import VTableHeader from '../components/VTableHeader.vue';

    export default {
        name: 'Printers',
        components: {
            VTable,
            VTableHeader,
            LoadingSpinner,
            LoadingError,
        },
        data() {
            return {
                loading: true,
                error: null,
                printers: [],
                editLock: false,
                defaultPrinters: {},
            };
        },
        async mounted() {
            try {
                const [{ data: printers }, { data: defaultPrinters }] =
                    await Promise.all([getPrinterList(), getDefaultPrinters()]);

                this.printers = printers;
                this.defaultPrinters.LETTER = printers
                    .find(printer => printer.id === defaultPrinters.default_letter_printer);
                this.defaultPrinters.LABEL = printers
                    .find(printer => printer.id === defaultPrinters.default_label_printer);
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        methods: {
            async setPrinterAsDefault(printer) {
                this.editLock = true;
                try {
                    await setDefaultPrinter(printer.id);
                    this.defaultPrinters[printer.paper_size] = printer;
                } catch (error) {
                    this.$alerts.danger('Unable to Change Default Printer');
                } finally {
                    this.editLock = false;
                }
            },
            async unsetPrinterAsDefault(printer) {
                this.editLock = true;
                try {
                    await unsetDefaultPrinter(printer.id);
                    this.defaultPrinters[printer.paper_size] = null;
                } catch (error) {
                    this.$alerts.danger('Unable to Change Default Printer');
                } finally {
                    this.editLock = false;
                }
            },
            async deletePrinter(printer) {
                this.editLock = true;
                try {
                    await deletePrinter(printer.id);
                    this.printers.splice(this.printers.indexOf(printer), 1);
                    if (this.defaultLetterPrinter == printer) { this.defaultLetterPrinter = null; }
                    if (this.defaultLabelPrinter == printer) { this.defaultLabelPrinter = null; }
                } catch (err) {
                    this.$alerts.danger('Unable to Delete Printer', 'If the problem persists, please contact support.');
                } finally {
                    this.editLock = false;
                }
            },
            humanizePaperSize(paperSize) {
                switch (paperSize) {
                case 'LETTER': return 'letter';
                case 'LABEL': return 'label';
                default: return 'unknown';
                }
            },
        },
    };
</script>
